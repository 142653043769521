import { mapHelper } from 'common/utils'

// 计划状态
const status = [
  {
    text: '未开始',
    value: 0
  },
  {
    text: '已开始',
    value: 1
  },
  {
    text: '已完成',
    value: 2
  },
  {
    text: '已结束',
    value: 3
  },
  {
    text: '已作废',
    value: 4
  }
]

const {
  map: statusMap,
  setOps: statusOps
} = mapHelper.setMap(status)

// 计划层级
const scopeType = [
  {
    text: '平台级',
    value: 1
  },
  {
    text: '公司级',
    value: 2
  },
  {
    text: '项目级',
    value: 3
  }
]

const {
  map: scopeTypeMap,
  setOps: scopeTypeOps
} = mapHelper.setMap(scopeType)

// 所有检查对象统一频次
const isPeriodAllSame = [
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]

const {
  map: isPeriodAllSameMap,
  setOps: isPeriodAllSameOps
} = mapHelper.setMap(isPeriodAllSame)

// 检查频次类别
const period = [
  {
    text: '周',
    value: 1
  },
  {
    text: '月',
    value: 2
  },
  {
    text: '季度',
    value: 3
  },
  {
    text: '半年',
    value: 4
  },
  {
    text: '年',
    value: 5
  }
]

const {
  map: periodMap,
  setOps: periodOps
} = mapHelper.setMap(period)

// 计划类型
const planType = [
  {
    text: '品质督查',
    value: 1
  },
  {
    text: '项目自查',
    value: 2
  },
]

const {
  map: planTypeMap,
  setOps: planTypeOps
} = mapHelper.setMap(planType)


// // 检查频次次数
// const period = [
//   {
//     text: 1,
//     value: 1
//   },
//   {
//     text: 2,
//     value: 2
//   },
//   {
//     text: 3,
//     value: 3
//   },
//   {
//     text: 4,
//     value: 4
//   },
//   {
//     text: 5,
//     value: 5
//   }
// ]

// const {
//   map: periodMap,
//   setOps: periodOps
// } = mapHelper.setMap(period)

const communityStatus = [
  {
    text: '正常',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  }
]
const {
  map: communityStatusMap,
  setOps: communityStatusOps
} = mapHelper.setMap(communityStatus)

// 区域类型
const searchRegionType = [
  {
    text: '公司',
    value: 0
  },
  // {
  //   text: '园区公司分公司',
  //   value: 1
  // },
  {
    text: '管理项目组',
    value: 2
  }
]

const {
  map: searchRegionTypeMap,
  setOps: searchRegionTypeOps
} = mapHelper.setMap(searchRegionType)

export {
  statusMap,
  statusOps,
  scopeTypeMap,
  scopeTypeOps,
  isPeriodAllSameMap,
  isPeriodAllSameOps,
  periodMap,
  periodOps,
  communityStatusMap,
  communityStatusOps,
  searchRegionTypeMap,
  searchRegionTypeOps,
  planTypeOps,
  planTypeMap,
}
