<!--
 * @Description: 品质管理-检查计划
 * @Author: 马俊
 * @Date: 2021-04-27 11:16:21
 * @LastEditors: 马俊
 * @LastEditTime:
 -->
<template>
  <div class="qualityInspectionPlanList-wrapper">
    <list ref="list" :exportPermission="'export'" :searchUrl="searchUrl" :exportUrl="exportUrl"
      :exportMethod="exportMethod" :searchParams="searchParams" :isMultiSelect="true" :headers="headers"
      @selectionChange="selectionChange">
      <template #headerSlot>
        <v-button text="生成本次计划任务" permission="generate" @click="generateAllScheduledTasks"></v-button>
        <v-button text="新增" permission="add" @click="add" />
      </template>

      <template #searchSlot>
        <v-input label="计划名称" v-model="searchParams.name" />
        <v-select label="计划类型" v-model="searchParams.planType" :options="planTypeOps" />
        <v-select label="计划层级" v-model="searchParams.scopeType" :options="scopeTypeOps" />

        <!-- <v-select label="检查对象" v-model="searchParams.communityId" :options="[]"/> -->
        <v-select2 label="检查对象" v-model="searchParams.communityId" v-bind="communityParams" />
        <v-select label="计划状态" v-model="searchParams.status" :options="statusOps" />
        <v-datepicker type="rangedatetimer" label="计划开始时间" format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.planStartTimeS" :endTime.sync="searchParams.planStartTimeE" />
        <v-datepicker type="rangedatetimer" label="计划完成时间" format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.planEndTimeS" :endTime.sync="searchParams.planEndTimeE" />
        <v-datepicker type="rangedatetimer" label="实际开始时间" format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.realStartTimeS" :endTime.sync="searchParams.realStartTimeE" />
        <v-datepicker type="rangedatetimer" label="实际完成时间" format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.realEndTimeS" :endTime.sync="searchParams.realEndTimeE" />
      </template>
      <template #operateSlot="scope">
        <v-button text="查看" type="text" permission="view" @click="view(scope.row)" />
        <template v-if="showButton(scope.row)">
          <v-button text="变更计划" type="text" v-if="isEditPlan(scope.row)" permission="status" @click="edit(scope.row)" />
          <v-button text="执行计划" type="text" v-if="isStartPlan(scope.row)" permission="status" @click="start(scope.row)" />
          <v-button text="终止计划" type="text" v-if="isStopPlan(scope.row)" permission="status" @click="stop(scope.row)" />
          <v-button text="生成计划报告" type="text" v-if="isCreatePlan(scope.row)" permission="create"
            @click="create(scope.row)" />
        </template>
      </template>
    </list>
    <el-dialog class="dialog" :title="dialogTitle" width="500px" :visible.sync="dialogVisible">
      <div class="dialog-content">
        <ul class="community-list">
          <li v-for="(item, index) in dialogContent" :key="index" :title="item">{{ item }}</li>
          <!-- <li v-for="(item, index) in 5" :key="index">上课解放拉萨解放的拉萨扩大飞机啊了</li> -->
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import {
  getQualityCheckPlanListURL,
  exportUrl,
  startOrStopQualityCheckPlanURL,
  generatedQualityCheckPlanTaskURL
} from './api'
import { _localStorage, sessionStorageHelper } from 'common/utils'
import {
  statusMap,
  statusOps,
  scopeTypeOps,
  scopeTypeMap,
  planTypeOps,
  planTypeMap
} from './map'
import { communityParams } from 'common/select2Params'
import { createAlinkVNode } from 'common/vdom'

export default {
  name: 'qualityInspectionPlanList',
  computed: {
    scopeTypeOps () {
      const userType = +this.$store.state.userInfo.userType
      let list = []
      if ([106, 100].includes(userType)) {
        list = scopeTypeOps()
      } else if ([102].includes(userType)) {
        list = scopeTypeOps().slice(1)
      } else if ([101].includes(userType)) {
        list = scopeTypeOps().slice(2)
      }
      list.unshift({
        text: '全部',
        value: undefined,
        label: '全部'
      })
      return list
    },
  },
  data () {
    const _this = this
    return {
      searchUrl: getQualityCheckPlanListURL,
      exportUrl,
      exportMethod: 'get',
      communityParams,
      statusMap,
      statusOps: statusOps(1),
      // scopeTypeOps: scopeTypeOps(1),
      planTypeOps: planTypeOps(1),
      scopeTypeMap,
      searchParams: {
        name: undefined,
        scopeType: undefined,
        communityId: undefined,
        status: undefined,
        planStartTimeS: '',
        planStartTimeE: '',
        planEndTimeS: '',
        planEndTimeE: '',
        realStartTimeS: '',
        realStartTimeE: '',
        realEndTimeS: '',
        realEndTimeE: ''
      },
      headers: [
        {
          prop: '',
          label: '计划类型',
          formatter (row) {
            return planTypeMap[row.planType]
          }
        },
        {
          prop: 'name',
          label: '计划名称'
        },
        {
          prop: 'templateName',
          label: '检查表模板'
        },
        {
          prop: 'planStartTime',
          label: '计划开始时间'
        },
        {
          prop: 'planEndTime',
          label: '计划结束时间'
        },
        {
          prop: 'realStartTime',
          label: '实际开始时间'
        },
        {
          prop: 'realEndTime',
          label: '实际结束时间'
        },
        {
          prop: 'scopeType',
          label: '计划层级',
          formatter (row) {
            return scopeTypeMap[row.scopeType]
          }
        },
        {
          prop: 'communityCount',
          label: '检查对象',
          formatter (row, prop) {
            return createAlinkVNode(_this, row, prop, {
              text: row[prop],
              cb: _this.showDetailContent
            })
          }
        },
        {
          prop: 'responsibleUserName',
          label: '计划责任人'
        },
        {
          prop: 'status',
          label: '计划状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
      ],
      /* dialog */
      dialogTitle: '检查对象',
      dialogContent: [],
      responsibleUserOps: [],
      dialogVisible: false,
      selectIds: []
    }
  },
  watch: {
  },
  methods: {
    // 选择
    selectionChange (val) {
      this.selectIds = val
    },
    add () { // 新增
      this.$router.push({
        name: 'qualityInspectionPlanForm'
      })
    },
    create () { // 生产计划报告
      this.$router.push({
        name: 'qualityPlanReportList'
      })
    },
    view (row) {
      this.$router.push({
        name: 'qualityInspectionPlanForm',
        query: {
          id: row.id,
          type: 'view'
        }
      })
    },
    edit (row) { // 变更计划
      this.$router.push({
        name: 'qualityInspectionPlanForm',
        query: {
          id: row.id
        }
      })
    },
    start (row) {
      this.updateStatus(row, 1)
    },
    stop (row) {
      this.updateStatus(row, 4)
    },
    showDetailContent (row) {
      this.dialogVisible = true
      this.dialogContent = row.communityNames.split(',')
      console.log(this.dialogContent, 'this.dialogContent')
    },
    /* 按钮权限 */
    isViewPlan (row) {
      return row.status === 3 || row.status === 4 // 已结束or已作废
    },
    isEditPlan (row) {
      return row.status === 0 // 未开始
    },
    isStartPlan (row) {
      return row.status === 0 // 未开始
    },
    isStopPlan (row) {
      const time = row.planEndTime
      let onOff = true
      if (time && moment().isAfter(time)) {
        onOff = false
      }
      return row.status === 1 && onOff // 已开始且未到结束时间
    },
    isCreatePlan (row) {
      return row.status === 2 // 已完成
    },
    showButton (row) {
      // 1.计划类型为平台级别的时候，只有系统/集团账号可以操作，公司/项目账号不可以操作
      // 2.计划类型为公司级别的时候，只有系统/集团/公司账号可以操作，项目账号不可以操作
      // 102 公司 101 项目
      const userType = this.$store.state.userInfo.userType
      switch (row.scopeType) {
        case 1:
          if (userType == '102' || userType == '101') {
            return false
          }
          break;
        case 2:
          if (userType == '101') {
            return false
          }
          break;
        default:
          break;
      }
      return true
    },
    /* 接口类 */
    updateStatus (row, status) {
      // status 1 执行 4 终止
      // let message = `是否${status === 1 ? '执行' : '终止'}此计划,请确认操作！`
      let message = `
        您将${status === 1 ? '执行' : '终止'}“${row.name}”计划，
        ${status === 1 ? `该计划将创建${row.communityCount}项检查项，并且立即下发` : '终止计划后，该计划将无法重新执行。'}。
        是否确定${status === 1 ? '执行' : '终止'}该计划？
      `
      this.$confirm(message).then((result) => {
        if (!result) {
          return
        }
        this.$axios.post(startOrStopQualityCheckPlanURL, {
          id: row.id,
          status: status
        }).then((res) => {
          if (res.status === 100) {
            this.$message.success('操作成功')
            this.$refs.list.searchData()
          }
        })
      })
    },
    // 批量生成本次计划任务 弹窗提示
    generateAllScheduledTasks () {
      let _this = this;
      this.$confirm('该操作将立即生成大量检查任务数据，是否确定继续操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        if (res) {
          _this.$axios.get(`${generatedQualityCheckPlanTaskURL}?planIdList=${this.selectIds.join()}`).then(res => {
            if (res.status === 100) {
              _this.$message({
                type: 'success',
                message: '生成任务成功!'
              });
              this.$refs.list.$refs.tablePanel.toggleSelection()
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.qualityInspectionPlanList-wrapper {
  .dialog {
    .dialog-content {
      padding: 0 5px;
      font-size: 16px;
      color: #606266;
      line-height: 40px;
      max-height: 60vh;
      overflow-y: auto;

      .community-list {
        li {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
